import React from "react";
import { Helmet} from "react-helmet";


const hideWidget = () => {
    return (
      <Helmet>
      <style type="text/css">
        {`
          iframe[title="Close message"],
          iframe[title="Message from company"],
          iframe[title="Button to launch messaging window"]  {
            display: none;
          }
        `}
      </style>
    </Helmet>
    )
};

export default hideWidget;

